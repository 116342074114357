<template>
  <ion-item :lines="lines" class="ion-no-padding">
    <ion-label>
      <div class="h6">
        {{ property.name }}
      </div>
      
    </ion-label>
    <ion-label slot="end" class="ion-text-right ion-no-margin">
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel } from "@ionic/vue";

export default {
  props: {
    property: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },
  components: {
    IonItem,
    IonLabel,
  },
};
</script>

<style scoped>
ion-badge {
  font-weight: 300;
}

ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}
</style>