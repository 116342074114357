<template>
  <base-layout-two page-title="Properties" page-default-back-link="/more" content-class="background-white">
    <section class="padding-x-16">
      <h1>Properties</h1>
      <ion-button class="regular-button" expand="block" router-link="/properties/create"
        >New Property</ion-button
      >
    </section>
    
    <ion-list class="padding-x-16">
      <property-list-item
        v-for="property in properties"
        :key="property.id"
        :router-link="`/properties/${property.id}`"
        :property="property"
      >
      </property-list-item>
    </ion-list>
  </base-layout-two>
</template>

<script>
import { IonList, IonButton } from "@ionic/vue";
import PropertyListItem from "@/components/properties/PropertyListItem.vue"

export default {
  components: {
    IonList,
    IonButton,
    PropertyListItem
  },

  data() {
    return {
      properties: [],
    };
  },

  ionViewWillEnter() {
    this.fetchProperties();
  },

  methods: {
    async fetchProperties() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/properties`)
        .then((response) => {
          this.properties = response.data.properties;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>